/* @import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "spaceMono";
  font-weight: 400;
  src: url("assests/fonts/SpaceMono-Regular.ttf");
}

@font-face {
  font-family: "spaceMono";
  font-weight: 700;
  src: url("assests/fonts/SpaceMono-Bold.ttf");
}
